<template>
  <div name="eventChart">

  <section class="bg-white rounded w-95p mx-auto px-6 pb-6 pt-4 mt-4 mb-8" >
     <h4 class="text-lg font-semibold mb-6">Participating in Events</h4>
      
        <div v-if="componentLoaded">
                    <div class="grid grid-cols-2 gap-4 mt-8">

                    <div>
                      <donutChart  :data-source="reppingEvent" :chart-title="'At an event'"></donutChart>
                    </div>


                     <div>
                        <donutChart :data-source="reppingEventAtLevel" :chart-title="'At levels'"></donutChart>
                      
                    </div>

                  </div>
                </div>

                   <p class="empty" v-else>We could not find any data for your given selection.</p>
                </section>



  <section class="bg-white rounded w-95p mx-auto px-6 pb-6 pt-4 mt-4 mb-8" >
       <h4 class="text-lg font-semibold mb-6">Analysis of Events</h4>

                  <div class="grid grid-cols-2 gap-4" v-if="componentLoaded">

                    <div>
                        <donutChart :data-source="levelChartData" :chart-title="'By Level'"></donutChart>
                    </div>



                      <div>
                          <donutChart :data-source="typeChartData" :chart-title="'By type'"></donutChart>

                      </div>

                  </div>

                   <p class="empty" v-else>We could not find any data for your given selection.</p>


                </section>

   
  </div>
</template>

<script>
const donutChart = () => import("@/components/charts/DonutChart.vue");

export default {
  name: "eventChart",
  props: {
      pupilCount: {type: Number, required: true},
      events: {type: Array, required: true},
  },
  computed: {
    componentLoaded() {
      return this.events.length > 0;
    },
    reppingEvent() {
      if (!this.componentLoaded) {
        return null;
      }

      var list = this.events.filter(function(row) {
        let filtered = true;
        filtered = row.registers != null && row.registers.length > 0;

        return filtered;
      });

      let repping = list.flatMap(function(event) {
        return event.registers.map(function(reg) {
          return reg.identifier;
        });
      });

      let unique = Array.from(new Set(repping)).length;

      return [
        { name: "Attending", value: unique, color: "#A3E9D2" },
        {
          name: "Non-attending",
          value: this.pupilCount - unique,
          color: "#FBB3B3"
        }
      ];
    },
    reppingEventAtLevel() {
      if (!this.componentLoaded) {
        return null;
      }

      var list = this.events.filter(function(row) {
        let filtered = true;
        filtered = row.registers != null && row.registers.length > 0;

        return filtered;
      });

      let data = this.levels.map(function(level) {
        var filtered = list.filter(function(row) {
          let filtered = true;
          filtered = row.level === level.value;
          return filtered;
        });

        let repping = filtered.flatMap(function(event) {
          return event.registers.map(function(reg) {
            return reg.identifier;
          });
        });

        //let unique = Array.from(new Set(repping)).length;
        let unique = Array.from(repping).length;

        return { name: level.title, value: unique, color: level.color };
      });

      return data;
    },
    levelChartData() {

      let eventList = this.events;
      let data = this.levels.map(level => {
        var list = eventList.filter(event => {
          let filtered = true;
          filtered = event.level === level.value;
          return filtered;
        });

        return {
          name: level.title,
          value: list.length,
          color: level.color
        };
      });

      return data;
    },
    typeChartData() {
      if (!this.componentLoaded) {
        return null;
      }

      return [
        {
          name: "Other",
          value: this.getCountByProgramType("other"),
          color: "#EBEBEB"
        },
        {
          name: "CPD",
          value: this.getCountByProgramType("cpd"),
          color: "#FFEAAA"
        },
        {
          name: "Swimming",
          value: this.getCountByProgramType("swimming"),
          color: "#A7C2FF"
        },
        {
          name: "Health Based",
          value: this.getCountByProgramType("health"),
          color: "#A3E9D2"
        },
        {
          name: "PE Based",
          value: this.getCountByProgramType("education"),
          color: "#FBB3B3"
        }
      ];
    }
  },
  data() {
    return {
      levels: [
        { value: 3, title: "Level 3", color: "#A3E9D2" },
        { value: 2, title: "Level 2", color: "#FFEAAA" },
        { value: 1, title: "Level 1", color: "#FBB3B3" },
        { value: 0, title: "No Level", color: "#A7C2FF" }
      ]
    };
  },
  created() {},
  mounted() {},
  methods: {
    getCountByProgramType(type) {
      var list = this.events.filter(function(row) {
        let filtered = true;
        filtered = row.program_type === type;
        return filtered;
      });

      return list.length;
    }
  },
  components: {
    donutChart
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
